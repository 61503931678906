export default {
  FS_USERS: {
    path: '/fs-users',
    name: 'fs_users',
    meta: {
      requiresAuth: true
    },
    component: () => import('@fs_views/Users/UsersIndex')
  },
  FS_COMPLETES: {
    path: '/fs-completes',
    name: 'fs_completes',
    meta: {
      requiresAuth: true,
      quickLink: true,
      project: 'fs',
      title: 'Completes',
      order: 4
    },
    component: () => import('@fs_views/Completes/index')
  },
  FS_USER_DETAILS: {
    path: '/fs-user/:userId',
    name: 'fs_user_details',
    meta: {
      requiresAuth: true,
      quickLink: true,
      project: 'fs',
      title: 'User Details',
      order: 0,
      main: true
    },
    component: () => import('@fs_views/Users/UserDetails')
  },
  FS_CLAIMS: {
    path: '/fs-claims',
    name: 'fs_claims',
    meta: {
      requiresAuth: true,
      quickLink: true,
      project: 'fs',
      title: 'Claims',
      order: 2
    },
    component: () => import('@fs_views/Claims/ClaimsIndex')
  },
  FS_EMAILS: {
    path: '/fs-emails',
    name: 'fs_emails',
    meta: {
      requiresAuth: true,
      quickLink: true,
      project: 'fs',
      title: 'E-Mails',
      order: 3
    },
    component: () => import('@fs_views/Emails/EmailsIndex')
  },
  FS_EMAIL_STATISTICS: {
    path: '/fs-email-statistics',
    name: 'fs_email_statistics',
    meta: {
      requiresAuth: true
    },
    component: () => import('@fs_views/Emails/EmailStatisticsIndex.vue')
  },
  FS_TRANSACTIONS: {
    path: '/fs-transactions',
    name: 'fs_transactions',
    meta: {
      requiresAuth: true,
      quickLink: true,
      project: 'fs',
      title: 'Transactions',
      order: 1
    },
    component: () => import('@fs_views/Transactions/TransactionsIndex')
  },
  FS_PERFORMANCE: {
    path: '/fs-performance',
    name: 'fs_performance',
    meta: {
      requiresAuth: true
    },
    component: () => import('@fs_views/Performance/PerformanceIndex')
  },
  FS_REWARDS: {
    path: '/fs-rewards',
    name: 'fs_rewards',
    meta: {
      requiresAuth: true
    },
    component: () => import('@fs_views/Rewards')
  },
  FS_AFFILIATE_PARTNERS: {
    path: '/fs-affiliate-partners',
    name: 'fs_affiliate_partners',
    meta: {
      requiresAuth: true
    },
    component: () => import('@fs_views/AffiliatePartners/AffiliatePartnersIndex')
  },
  FS_TRIGGERS: {
    path: '/fs-triggers',
    name: 'fs_triggers',
    meta: {
      requiresAuth: true
    },
    component: () => import('@fs_views/Triggers/TriggersIndex')
  },
  FS_COHORT_ANALYSIS: {
    path: '/fs-cohort-analysis',
    name: 'fs_cohort_analysis',
    meta: {
      requiresAuth: true
    },
    component: () => import('@fs_views/CohortAnalysis/CohortAnalysisIndex.vue')
  },
  FS_BOGDAN_BONUS: {
    path: '/fs-bogdan-bonus',
    name: 'fs_bogdan_bonus',
    meta: {
      requiresAuth: true
    },
    component: () => import('@fs_views/BogdanBonus')
  },
  FS_LOG_SPEND: {
    path: '/fs-log-spend',
    name: 'fs_log_spend',
    meta: {
      requiresAuth: true
    },
    component: () => import('@fs_views/LogSpend')
  }
}