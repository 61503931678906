/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import Router from 'vue-router'
import store from './../store/index'
import PagesAndPermissions from './allPagesAndPermissions.js'
import baseRouterConfigs from './router-configs/baseRouterConfigs'
import poRouterConfigs from './router-configs/poRouterConfigs'
import fsRouterConfigs from './router-configs/fsRouterConfigs'
import hcRouterConfigs from './router-configs/hcRouterConfigs'
import psaRouterConfigs from './router-configs/psaRouterConfigs'


Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior () {
    return {x: 0, y: 0}
  },
  routes: Object.values({
    ...baseRouterConfigs,
    ...poRouterConfigs,
    ...fsRouterConfigs,
    ...hcRouterConfigs,
    ...psaRouterConfigs
  })
})

router.beforeEach((to, from, next) => {
  const isLoggedIn = store.getters['auth/isLoggedIn']
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!isLoggedIn) {
      next({
        path: '/login',
        query: {redirect: to.fullPath}
      })
    } else if (router.canAccessPage(to.name)) {
      // quick links bar
      manageQuickLinks(to)
      next()
    } else {
      router.redirectToMainPage(`router.beforeEach ${  to.name}`)
      return false
    }
  } else if (
    to.matched.some(record => record.meta.redirectOnLogin) &&
      isLoggedIn
  ) {
    next({
      path: '/'
    })
  } else {
    next()
  }
})

router.canAccessPage = (pageName) => {
  const adminInformation = store.getters['auth/admin']

  if (!adminInformation) {
    location.reload(true)
  }
  const adminId = adminInformation.id
  const superAdminPermissionModules = PagesAndPermissions.flatMap(({ always_available_for_ids, sub_permissions, route }) => {
    const subIds = sub_permissions
      ?.filter(({ always_available_for_ids }) => always_available_for_ids?.length > 0 && !always_available_for_ids.includes(adminId))
      .map(({ id }) => id)
    const id = always_available_for_ids?.length > 0 && !always_available_for_ids.includes(adminId) ? route : null

    return [...subIds, id].filter(id => id)
  })

  if (
    ((adminInformation.role === window.CONST_ADMIN_ROLE_SUPER_ADMIN) && !superAdminPermissionModules.includes(pageName)) ||
        (adminInformation.permissions.modules instanceof Array && adminInformation.permissions.modules.indexOf(pageName) >= 0)
  ) return true
}

router.redirectToMainPage = (reason) => {
  // console.log('router.redirectToMainPage ' + reason);
  const adminInformation = store.getters['auth/admin']
  if (adminInformation) {
    //We need to store main_page for each admin
    //   console.log(adminInformation.permissions.main_page)
    const adminMainPage = typeof adminInformation.permissions.main_page === 'string' ? adminInformation.permissions.main_page : null

    //If we found main page, we redirect to main page
    let filterPages = PagesAndPermissions.filter((item) => {
      const mainPages = item.sub_permissions.filter((p) => {
        return p.main === true
      })
      return mainPages[0] && mainPages[0].id === adminMainPage
    })
    if (filterPages.length > 0) {
      router.push(router.getRouteWithName(filterPages[0].route))
      return
    }

    //If we didn't find out main page, if role is super admin, we redirect to the first page
    //It's because admin can access all the pages
    if (adminInformation.role === window.CONST_ADMIN_ROLE_SUPER_ADMIN) {
      router.push(router.getRouteWithName(PagesAndPermissions[0].route))
      return
    }

    const allAccessedModules = adminInformation.permissions.modules
    filterPages = PagesAndPermissions.filter((item) => {
      for (let i = 0; i < item.sub_permissions.length; i++) {
        if (
          item.sub_permissions[i].main === true &&
          allAccessedModules.indexOf(item.sub_permissions.id) >= 0
        ) {
          return true
        }
      }
      return false
    })
    if (filterPages.length > 0) {
      router.push(router.getRouteWithName(filterPages[0].route))
      return
    }

    throw new Error('Admin settings is wrong. Check router.js')
  } else {
    throw new Error('Admin information is wrong')
  }
}

router.getRouteWithName = (routerName) => {
  let listRouters = []
  router.options.routes.forEach(route => {
    if (route.children instanceof Array) {
      listRouters = listRouters.concat(route.children)
    } else {
      listRouters.push(route)
    }
  })
  const filterRouters = listRouters.filter(function (item) {
    return item.name === routerName
  })
  if (filterRouters.length === 1) return filterRouters[0]
  throw new Error('2 or more routers with the same name')
}

function sendViewLog (fullIrl) {
  window.PRIME_VUE_OBJECT.$http.post('/api/admin-logs', {
    type: 'view',
    request_url: fullIrl
  })
    .catch(error => {
      console.error('Error logging page view:', error)
    })
}

function manageQuickLinks (to) {
  if (to.meta?.quickLink) {
    if (to.meta?.main && to.params?.userId) {
      store.commit('app/SET_QUICK_LINK_PROPERTIES', {
        display: true,
        user_id: to.params.userId,
        project: to.meta.project
      })
    } else {
      const quickLinkProperties = store.getters['app/quickLinkProperties']
      if (quickLinkProperties?.display) {
        const queryIntersection = ['filterUserId', 'filter[userId]'].filter(value => Object.keys(to.query).includes(value))
        if (queryIntersection.length === 0) {
          store.commit('app/SET_QUICK_LINK_PROPERTIES', {
            display: false,
            user_id: null,
            project: null
          })
        }
      }
    }
  } else {
    store.commit('app/SET_QUICK_LINK_PROPERTIES', {
      display: false,
      user_id: null,
      project: null
    })
  }
}

router.afterEach((page) => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }

  const fullUrl = new URL(page.fullPath, window.location.origin).href

  if (page.path !== '/login') {
    setTimeout(() => sendViewLog(fullUrl), 1000)
  }
})

export default router
