import {$themeBreakpoints} from '@themeConfig'
import baseRouterConfigs from '@/main/router-configs/baseRouterConfigs'
import poRouterConfigs from '@/main/router-configs/poRouterConfigs'
import fsRouterConfigs from '@/main/router-configs/fsRouterConfigs'
import hcRouterConfigs from '@/main/router-configs/hcRouterConfigs'
import psaRouterConfigs from '@/main/router-configs/psaRouterConfigs'

export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    shallShowOverlay: false,
    quickLinks: [],
    quickProperties: {
      display: false,
      user_id: null,
      project: null
    },
    quickLinkExternalsByProject: {
      po: {
        intercom: {
          path: 'intercom',
          type: 'external',
          url: null,
          target: '_blank',
          title: 'Intercom Profile',
          meta: {
            order: 5
          }
        }
      },
      fs: {
        intercom: {
          path: 'intercom',
          type: 'external',
          url: null,
          target: '_blank',
          title: 'Intercom Profile',
          meta: {
            order: 5
          }
        }
      }
    }
  },
  getters: {
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
    quickLinks: state => {
      return state.quickLinks
    },
    quickLinkProperties: state => {
      return state.quickProperties
    }
  },
  mutations: {
    UPDATE_WINDOW_WIDTH (state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY (state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
    SET_QUICK_LINKS (state) {
      const project = state.quickProperties.project ?? 'ps'
      state.quickLinks = Object.values({
        ...baseRouterConfigs,
        ...poRouterConfigs,
        ...fsRouterConfigs,
        ...hcRouterConfigs,
        ...psaRouterConfigs
      }).filter(function (item) {
        return item.meta?.quickLink && item.meta?.project === project
      })
      if (state.quickLinkExternalsByProject[project]) {
        state.quickLinks = [...state.quickLinks, ...Object.values(state.quickLinkExternalsByProject[project])]
      }
      state.quickLinks.sort((a, b) => a.meta.order - b.meta.order)
    },
    SET_QUICK_LINK_PROPERTIES (state, val) {
      state.quickProperties = val
    },
    SET_QUICK_LINK_USER_ID (state, val) {
      state.quickProperties.user_id = val
    },
    SET_QUICK_LINK_EXTERNAL_URL (state, linkDetails) {
      if (linkDetails.key && linkDetails.value) {
        state.quickLinkExternalsByProject[state.quickProperties.project][linkDetails.key]['url'] = linkDetails.value
      }
    }
  },
  actions: {}
}
